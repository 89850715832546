import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { Button, Input } from "@nextui-org/react";
import { parseWithZod } from "@conform-to/zod";
import schema from "~/schemas/signin";
import { authenticator } from "~/services/auth.server";
import { getFormProps, useForm } from "@conform-to/react";
import { AuthorizationError } from "remix-auth";
import { commitSession, getSession } from "~/services/session.server";
import Toast from "~/components/Toast";
import * as m from "$paraglide/messages";
import NavBar from "~/components/Layouts/NavBar";
import { defaultRedirect } from "~/models/user.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await authenticator.isAuthenticated(request);

  if (user) {
    const session = await getSession(request.headers.get("Cookie"));
    session.set(authenticator.sessionKey, user);
    const headers = new Headers({ "Set-Cookie": await commitSession(session) });
    const { searchParams } = new URL(request.url);
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      return redirect(redirectTo, { headers });
    }
    const redirectUrl = await defaultRedirect(user);
    return redirect(redirectUrl, { headers });
  }
  return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.clone().formData();
  const submission = parseWithZod(formData, { schema });

  if (submission.status !== "success") {
    return submission.reply();
  }

  try {
    const user = await authenticator.authenticate("user-pass", request, {
      throwOnError: true,
    });
    const session = await getSession(request.headers.get("Cookie"));
    session.set(authenticator.sessionKey, user);
    const headers = new Headers({ "Set-Cookie": await commitSession(session) });

    if (submission.value.redirectTo) {
      return redirect(submission.value.redirectTo, { headers });
    }
    const redirectUrl = await defaultRedirect(user);
    return redirect(redirectUrl, { headers });
  } catch (error) {
    if (error instanceof Response) return error;
    if (error instanceof AuthorizationError) {
      return json(
        {
          status: "error",
          submission: {
            ...submission,
            error: {
              email: [m.chunky_light_skunk_hike()],
            },
          },
        },
        { status: 401 }
      );
    }
    return json({ status: "error", submission }, { status: 400 });
  }
};

export const meta: MetaFunction = () => [{ title: "Login" }];

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const actionData = useActionData<typeof action>();

  const [form, { email, password }] = useForm({
    lastResult: actionData?.submission,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <>
      <NavBar />
      <div className="max-w-lg mx-auto bg-white p-8 md:rounded-xl md:shadow md:shadow-slate-300 mt-8">
        <h1 className="text-2xl font-bold leading-9 text-gray">
          {m.signInTitle()}
        </h1>
        <div className="mb-10">
          {m.arable_awful_pig_sprout()}
          <Link to={{
            pathname: "../signup",
            search: searchParams.toString(),
          }} className="underline ml-2" relative="path">
            {m.signUp()}
          </Link>
        </div>
        <Form method="post" {...getFormProps(form)}>
          <Input
            id="email"
            isRequired={true}
            name="email"
            type="email"
            label={m.signInEmail()}
            autoComplete="email"
            isInvalid={!!email.errors}
            color={email.errors ? "danger" : undefined}
            errorMessage={email.errors}
            className="mb-4"
          />

          <Input
            id="password"
            isRequired={true}
            name="password"
            type="password"
            label={m.signInPassword()}
            autoComplete="current-password"
            isInvalid={!!password.errors}
            color={password.errors ? "danger" : undefined}
            errorMessage={password.errors}
            className="mb-4"
          />
          {redirectTo && (
            <input type="hidden" name="redirectTo" value={redirectTo} />
          )}
          <div className="text-right text-sm text-gray-500">
            <Link
              className="underline"
              to={{
                pathname: "../password-reset-requests/new",
                search: searchParams.toString(),
              }}
              relative="path"
            >
              {m.signInForgotPassword()}
            </Link>
          </div>
          <Button type="submit" size="lg" fullWidth radius="full" className="mt-8 bg-gradient-to-tr from-warning to-danger text-white shadow-md border">
            {m.signIn()}
          </Button>
        </Form>
      </div>
    </>
  );
}
